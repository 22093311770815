<template>
  <html>
    <div class="app">
      <div class="container-fluid ">
      <body>
        <PrevisualizacionAutorizoDescuento/>
      </body>
      </div>
      <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
      <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
    </div>
  </html>
</template>

<script>
import PrevisualizacionAutorizoDescuento from "../../components/Cuenta/PrevisualizacionAutorizoDescuento.vue"
import {getTokenDecoden} from "@/helpers/tokenauth";
export default {
  data() {
    return {
      errors: [],
      apiToken: null,
    };
  },
  name: 'PrevisualizacionAutorizoDescuentoPrincipal',
  components: {
    PrevisualizacionAutorizoDescuento,
  },
   beforeMount() {
    this.checkLogin();
  },
  methods:{
    checkLogin(){
      var user = getTokenDecoden();
      if(user != null){
        if(user.obj['role'] == "administrator"){
          // window.location.href = "/admin/principal";
           this.$router.push({path:"/admin/principal"});
        } else if(user.obj['role'] == "loan_analyst"){
          // window.location.href = "/analyst/principal";
           this.$router.push({path:"/analyst/principal"});
        }
      }
      else{
        // window.location.href = "/"
         this.$router.push({path:"/"});
      }
    }
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}


  #button_popups{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #FEB72B;
    width: 200px;
    height: 30px;
    padding-top: 0%;
  }

  button {outline: none !important;}
</style>