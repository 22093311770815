<template>
  <div class=" mx-auto">
      <div class="row" style="padding:50px;">
        <Loading :isLoading="this.isLoading" />
        <div style="display:flex;justify-content:center">
          <div style="width:50%" class="grid grid-cols-2 gap-4">
            <div>
              <p></p>
            </div>
            <div style="text-align:right;">
              <p>FONCABSA, SC DE AP DE RL DE CV</p>
              <p>AUTORIZACIÓN PARA DESCUENTO POR NÓMINA</p>
            </div>
            <div class="col-span-2 containerDiv" style="padding:20px;">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <p>{{completeName}}</p>
                  <p>CAGI931105LX1</p>
                </div>
                <div style="text-align:right;">
                  <p>06/07/2021</p>
                  <p>{{geolocation}}</p>
                </div>
              </div>
            </div>
            <div class="col-span-2 containerDiv" style="padding:20px;">
              <p>
                POR ESTE CONDUCTO AUTORIZO PARA QUE LINEA MAGISTERIAL SAPI DE CV DESCUENTE DE MI SALARIO QUINCENAL LOS SIGUIENTES CONCEPTOS.
                MANIFIESTO QUE EL VALOR DE DESCUENTO DE ESTE DOCUMENTO ES DIJO, PERO PODRÁ VARIAR CUANDO LAS CONDICIONES DEL PRODUCTO SOLICITADO AL MOMENTO DE LA AUTORIZACIÓN, 
                SE MODIFIQUEN O BIEN SI DURANTE EL PLAZO DEL MISMO LAS CONDICIONES EN EL PLAZO Y MONTO, DERIVADO DE UN PAGO ANTICIPADO O ALGÚN ATRASO, 
                SUFRIERAN UN CAMBIO, POR LO QUE ACEPTO LAS VARIACIONES A LA ALZA O A LA BAJA POR LOS CORRESPONDIENTES AJUSTES QUE SE PRESENTEN.
              </p>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center;align-items:center;" class="">
          <div style="background-color:#BFD243;color:#FFF;display:flex;;width:50%" class="containerDiv">
            <div style="padding:2px;" class="grid grid-cols-1 gap-4">
              <div style="display:flex;align-items: center;">
                <p>AUTORIZO PARA DESCUENTO POR NÓMINA PARA AHORRO</p>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center">
          <div style="width:50%" class="grid grid-cols-2 gap-4">
            <div class="col-span-2 containerDiv" style="padding:20px;">
              <p>
                LA CANTIDAD DE {{$filters.formatCurrency(savingAmount)}}
              </p>
              <p>PARA QUE ME SEA DEPOSITADO EN MI CUENTA DE AHORRO.</p>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center;align-items:center;" class="">
          <div style="background-color:#BFD243;color:#FFF;display:flex;;width:50%" class="containerDiv">
            <div style="padding:2px;" class="grid grid-cols-1 gap-4">
              <div style="display:flex;align-items: center;">
                <p>AUTORIZO PARA DESCUENTO POR NÓMINA PARA PRESTAMO</p>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center">
          <div style="width:50%" class="grid grid-cols-2 gap-4">
            <div class="col-span-2 containerDiv" style="padding:20px;">
              <p>
                LA CANTIDAD DE $
              </p>
              <p>DURANTE QUINCENAS (S) O BIEN HASTA CUBRIR LA TOTALIDAD DEL PRÉSTAMO.</p>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center;align-items:center;" class="">
          <div style="background-color:#BFD243;color:#FFF;display:flex;;width:50%" class="containerDiv">
            <div style="padding:2px;" class="grid grid-cols-1 gap-4">
              <div style="display:flex;align-items: center;">
                <p>FORMA DE PAGO PARTE SOCIAL</p>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex;justify-content:center">
          <div style="width:50%" class="grid grid-cols-2 gap-4">
            <div class="col-span-2 containerDiv" style="padding:20px;">
              <p>
                PAGO EN BANCOS (ANEXAR FICHA DE DEPÓSITO BANCARIO)
              </p>
              <p style="text-align:center;margin-top:50px;">{{completeName}}</p>
            </div>
          </div>
        </div>
      </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>
  </div>
</template>

<script>
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Menu from '@/components/Menu.vue'
import Loading from '@/components/Loading/VueLoading.vue'
import Check from '@/components/Loading/VueCheck.vue'
import { getAdmissionRequest,getDataVerificationByProcess,getDataVerificationMati, saveRequest } from '@/api/user'
import Swal from "sweetalert2";
import {  getTokenDecoden } from "@/helpers/tokenauth";
import {useRouter} from "vue-router";
export default {
     data() {
      return {
        activeColor: '1',
        activeColor2: '0.4',
        personId: "",
        ccapi: process.env.VUE_APP_CCAPI,
        multiApi:process.env.VUE_APP_MULTIAPI,
        isLoading: false,
        loadCheckID: false,
        loadCheckCURP: false,
        loadCheckProofResidency: false,
        loadCheckProofIncome: false,
        loadCheckFinancialStatement: false,
        loadCheckRFC: false,
        curp: "",
        proofResidency: "",
        proofIncomes: [],
        rfc: "",
        documentsArray: {
          identifications: [],
          documents: [],
          proofIncomes: []
        },
        financialStatement: "",
        isFromMenu: false,
        member_id: "",
        mati_identity_id: "",
        isReady: false,
        existBeneficiaries: false,

        user_id:null,
        admission_id:"",
        admission_status: null,
        user_is_validated:false,
        data_verification_id: null,
        infoDocuments:null,
        message:null,
        nameDocumentsArray:[],
        base64Frontal:"",
        base64Trasera:"",
        extensionFrontal:"",
        extensionTrasera:"",
        savingAmount: "",
        completeName: "",
        rfc: "",
        geolocation: "",
      };
    },
    setup(){
      const router = useRouter();
      return { router }
    },
    name: 'CargaDocumentosss',
    components: {
      Menu,
      Loading,
      Check,
      MenuLateral
    },
    async mounted() {
    },
    async beforeMount() {
      await this.loadInfo();
    },
    computed: {
    },
    methods:{
      getRouterParams() {
        this.isFromMenu = this.$route.params.fromMenu == "1" ? true : false;
      },
      checkDocumentsLoaded() {
        return this.isReady;
      },
      getFileExtensionFromURL(url, paramName) {
        var url = url
        var urlVar = url.split('&');
          for (var i = 0; i < urlVar.length; i++) {
            var param = urlVar[i].split('=');
            if (param[0] == paramName) {
              return param[1];
            }
          }
        return null;
      },
      async loadInfo(){
        var auth = getTokenDecoden();
        this.personId = auth.obj.person['id'];
        this.user_id = auth["id"];
        await this.searchInfo();
      },
      async searchInfo() {
        let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
        var geolocalizacion = JSON.parse(window.sessionStorage.getItem("geolocalizacion"));
        this.geolocation = geolocalizacion
        if (_json != null) {
          this.savingAmount = json.data.response.admission_request.admission_information.saving_registration_attributes.amount;
          this.completeName = `${json.data.response.admission_request.admission_information.name} ${json.data.response.admission_request.admission_information.middle_name} ${json.data.response.admission_request.admission_information.paternal_name} ${json.data.response.admission_request.admission_information.maternal_name}`;
          this.rfc = json.data.response.admission_request.admission_information.rfc
        }
      },
      setDocumentSelected(documents, isDocumentSaved) {
        console.log("DOCUMENTS ",documents);
        console.log();
        this.documentsArray = {
          identifications: [],
          documents: [],
          proofIncomes: []
        };
        for (let i = 0; i < documents.length; i++) {
          if (isDocumentSaved) {
            let document_name = this.getFileNameFromURL(documents[i].url, "document_name");
            var documentItem = {
              id: documents[i].document_id,
              typeDocument: documents[i].abbreviation,
              name: document_name,
              correctName: documents[i].document_name,
              file: "",
              document_name: "Documentos_Socios",
              business_acronym:"FONCABSA",
              process:"documentos_alta_socio",
              extension: "",
              abbreviation: documents[i].abbreviation
            }
          }

          switch (documents[i].abbreviation) {
            case "IO":
              if (isDocumentSaved) {
                this.loadCheckID = true;
                console.log("setDocumentSelected2 ", this.loadCheckID);
                this.documentsArray.identifications.push(documentItem); 
              }
              break;
            case "CURP":
              this.curp = isDocumentSaved != true ? documents[i].document_name +"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckCURP = true;
              break;
            case "CD":
              this.proofResidency = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckProofResidency = true;
              break;
            case "CI":
              if (isDocumentSaved) {
                this.documentsArray.proofIncomes.push(documentItem);
              }
              this.loadCheckProofIncome = true;
              break;
            case "EC":
              this.financialStatement = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckFinancialStatement = true;
              break;
            case "RFCFIEL":
              this.rfc = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
              if (isDocumentSaved) {
                this.documentsArray.documents.push(documentItem);  
              }
              this.loadCheckRFC = true;
              break;
            default:
              break;
          }
        }
        this.validate();
        this.isLoading=false;
      },
      validate(){
        if (!this.loadCheckID || !this.loadCheckCURP || !this.loadCheckProofResidency || 
        !this.loadCheckProofIncome || !this.loadCheckFinancialStatement || !this.loadCheckRFC) {
          this.isReady = false;
        } else {
          this.isReady = true;
        }
      },
      setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
        sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
      },
      getJsonFromLocalStorageBy(user_id) {
        return JSON.parse(window.sessionStorage.getItem(user_id));
      },
      async showMessage(){
        this.isLoading=false;
        this.user_is_validated = false;
        await Swal.fire({
          title: "Aviso",
          text: "Para continuar con la carga de documentos favor de verificarse.",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        }).then((result) => {
          if (result.value) {
            // window.location.href = "/cuenta/registro-datos-personales"
            this.router.push({path:"/cuenta/registro-datos-personales"});
          }
        });
      },
      currency(value) {
        const options2 = { style: 'currency', currency: 'MXN' };
        //const formatter = new Intl.NumberFormat('es-MX', options2);
        const formatter = new Intl.NumberFormat('en-US', options2);

        if (value != "" && value != undefined) {
          var resultado = Number(value.toString().replace(/[^0-9.-]+/g,"")).toString();
        
          if(!value.toString().includes(".")){
              resultado = value.toString() + ".00"
          }
          
          if(!resultado.includes(",")){
              resultado = resultado.replace(",","")//formatter.format(value)
          }
          resultado = formatter.format(resultado)
          
          resultado = resultado.replace('MX$','')
          
          if (resultado =="NaN" || resultado < 0) {
              resultado = ""
          }
          return '$' + resultado;
        }
        return '$0.00';
      },
    },
}
</script>

<style>
.containerDiv {
  border: 1px solid #000;
}
</style>